<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Generate Promosi
        <div class="card-header-actions">
          <a
            class="card-header-action"
            href="promotion-codes"
            rel="noreferrer noopener"
          >
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form
              v-on:submit.prevent="onSubmit"
              novalidate
              action="https://vuejs.org/"
            >
              <!-- Code Type -->
              <b-form-group
                id="code_type"
                label="Code Type"
                label-for="code_type"
              >
                <b-form-select
                  id="code_type"
                  v-model.lazy.trim="$v.form.code_type.$model"
                  :state="chkState('code_type')"
                  :plain="true"
                  aria-describedby="codeTypeFeedback"
                  :options="[
                    { value: 0, text: 'General' },
                    { value: 1, text: 'Unique' }
                  ]"
                >
                </b-form-select>
                <b-form-invalid-feedback id="codeTypeFeedback">
                  <span v-if="!$v.form.code_type.required"
                    >Code Type tidak boleh kosong.</span
                  >
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- Modul -->
              <b-form-group label="Modul" label-for="basicInlineCheckboxess">
                <b-form-checkbox-group
                  id="basicInlineCheckboxess"
                  name="InlineCheckboxes"
                  v-model="form.module"
                >
                  <b-form-checkbox
                    v-for="(item, index) in modules"
                    :key="index"
                    :value="item.value"
                    >{{ item.text }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <!-- Promo Name -->
              <b-form-group id="name" label="Promo Name" label-for="name">
                <b-form-input
                  id="name"
                  type="text"
                  v-model.lazy.trim="$v.form.name.$model"
                  :state="chkState('name')"
                  aria-describedby="nameFeedback"
                  placeholder="Please Enter Name Promotion"
                  autocomplete="given-name"
                  autofocus
                />
                <b-form-invalid-feedback id="nameFeedback">
                  <span v-if="!$v.form.name.required"
                    >Nama Promosi tidak boleh kosong.</span
                  >
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- Description -->
              <b-form-group
                id="short_description"
                label="Short Description"
                label-for="short_description"
              >
                <b-form-input
                  id="short_description"
                  type="text"
                  v-model.lazy.trim="$v.form.short_description.$model"
                  :state="chkState('short_description')"
                  aria-describedby="shortFeedback"
                  placeholder="Please Enter Short Description"
                  autocomplete="given-name"
                  autofocus
                />
                <b-form-invalid-feedback id="shortFeedback">
                  <span v-if="!$v.form.short_description.required"
                    >Short Description tidak boleh kosong.</span
                  >
                  <span v-if="!$v.form.short_description.maxLength"
                    >Short Description tidak boleh lebih dari 80 Karakter.</span
                  >
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- Promo Code -->
              <b-form-group
                id="code"
                :label="
                  form.code_type === 1 ? 'Prefix Promo Code' : 'Promo Code'
                "
                label-for="code"
              >
                <b-form-input
                  id="code"
                  type="text"
                  v-model.lazy.trim="$v.form.code.$model"
                  :state="chkState('code')"
                  aria-describedby="codeFeedback"
                  placeholder="Please Enter Code Promotion"
                  autocomplete="given-name"
                  autofocus
                />
                <b-form-invalid-feedback id="codeFeedback">
                  <span v-if="!$v.form.code.required"
                    >Promo Code tidak boleh kosong.</span
                  >
                  <span v-if="!$v.form.code.maxLength"
                    >Promo Code tidak boleh lebih dari 15 Karakter.</span
                  >
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- Promo Period -->
              <b-form-group
                class="mb-3"
                label="Promo Period"
                label-for="daterange"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <i class="fa fa-calendar"></i>
                  </b-input-group-prepend>
                  <range-picker
                    id="daterange"
                    type="clear"
                    :start="startDate"
                    :end="endDate"
                    :timePicker="true"
                    @picker="changePicker"
                  >
                  </range-picker>
                </b-input-group>
                <div v-if="this.startDate == ''">
                  <p
                    id="dateFeedback"
                    v-for="(error, index) in errors.form.start"
                    :key="index"
                  >
                    - {{ error }} <br />
                  </p>
                </div>
              </b-form-group>
              <!-- Organization/Partner -->
              <b-form-group
                id="organization"
                label="Organisasi/Partner"
                label-for="organization"
              >
                <b-form-select
                  id="organization"
                  v-model.lazy.trim="form.usage_type"
                  aria-describedby="organizationFeedback"
                  :plain="true"
                  @change="setChange"
                  :options="[
                    { value: 0, text: 'All User' },
                    { value: 1, text: 'Treasury User' },
                    { value: 2, text: 'Partner' },
                    { value: 3, text: 'Organization' }
                  ]"
                >
                </b-form-select>
                <b-form-invalid-feedback
                  id="organizationFeedback"
                  v-for="(error, index) in errors.form.usage_type"
                  :key="index"
                >
                  - {{ error }} <br />
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- Spesifik User -->
              <b-form-group
                id="treasury"
                label="Spesifik User"
                v-if="this.form.usage_type == 1"
                label-for="treasury"
              >
                <b-form-select
                  id="treasury"
                  v-model="form.organization"
                  aria-describedby="treasuryFeedback"
                  :plain="true"
                  :options="[
                    { value: 1, text: 'Treasury & VIP Gold' },
                    { value: 2, text: 'Treasury Without VIP Gold' }
                  ]"
                >
                </b-form-select>
              </b-form-group>
              <!-- Partner -->
              <b-form-group
                id="partner"
                label="Partner"
                v-if="this.form.usage_type == 2"
                label-for="partner"
              >
                <b-form-select
                  id="partner"
                  v-model="form.organization"
                  :plain="true"
                  :options="partner"
                >
                </b-form-select>
              </b-form-group>
              <!-- Organization -->
              <b-form-group
                id="organization"
                label="Organization"
                v-if="this.form.usage_type == 3"
                label-for="organization"
              >
                <b-form-select
                  id="organization"
                  v-model="form.organization"
                  :plain="true"
                  :options="organization"
                >
                </b-form-select>
              </b-form-group>
              <!-- User Type -->
              <b-form-group
                id="user_type"
                label="User Type"
                label-for="user_type"
              >
                <b-form-select
                  id="user_type"
                  v-model="form.user_type"
                  aria-describedby="usertypeFeedback"
                  :plain="true"
                  :options="[
                    { value: 0, text: 'All User' },
                    { value: 1, text: 'New User' },
                    { value: 2, text: 'First Time Buyer' },
                    { value: 3, text: 'Last Time Buy' },
                    { value: 4, text: 'Register At' }
                  ]"
                >
                </b-form-select>
              </b-form-group>
              <!-- Register -->
              <b-row>
                <b-col cols="6" v-if="form.user_type === 4">
                  <div>
                    <b-form-group
                      class="mb-3"
                      label="Register Start :"
                      label-for="daterange"
                    >
                      <b-input-group>
                        <date-picker
                          v-model="form.register_start"
                          format="YYYY-MM-DD HH:mm:ss"
                          type="datetime"
                          lang="en"
                          value-type="format"
                          placeholder="YYYY-MM-DD HH:mm:ss"
                        ></date-picker>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="6" v-if="form.user_type === 4">
                  <div>
                    <b-form-group
                      class="mb-3"
                      label="Register End :"
                      label-for="daterange"
                    >
                      <b-input-group>
                        <date-picker
                          v-model="form.register_end"
                          format="YYYY-MM-DD HH:mm:ss"
                          type="datetime"
                          lang="en"
                          value-type="format"
                          :not-before="form.register_start"
                          placeholder="YYYY-MM-DD HH:mm:ss"
                        >
                        </date-picker>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <!-- Time Buy -->
              <b-form-group
                id="day"
                v-if="form.user_type === 3"
                label="Last Time Buy Day"
                label-for="day"
              >
                <b-form-input
                  id="day"
                  type="number"
                  v-model="form.day"
                  aria-describedby="dayFeedback"
                  placeholder="0"
                  autocomplete="given-name"
                  autofocus
                />
              </b-form-group>
              <!-- Payment Method -->
              <b-form-group
                id="payment_type"
                label="Payment Method"
                label-for="payment_type"
              >
                <b-form-select
                  id="payment_type"
                  v-model="form.payment_type"
                  :plain="true"
                  @change="setPaymentChange"
                  :options="[
                    { value: 0, text: 'All Payment Method' },
                    { value: 1, text: 'Virtual Account Bank' },
                    { value: 2, text: 'Dompet Digital' },
                    { value: 3, text: 'Debit Instant' },
                    { value: 4, text: 'Gerai Retail' }
                  ]"
                >
                </b-form-select>
              </b-form-group>
              <!-- Payment Place -->
              <b-form-group
                id="payment_method"
                :label="
                  form.payment_type === 1
                    ? 'Virtual Account Bank'
                    : form.payment_type === 2
                    ? 'Dompet Digital'
                    : form.payment_type === 3
                    ? 'Debit Instant'
                    : 'Gerai Retail'
                "
                v-if="this.form.payment_type !== 0"
                label-for="payment_method"
              >
                <b-form-select
                  id="payment_method"
                  v-model="form.payment_method"
                  :plain="true"
                  :options="payment_methods"
                >
                </b-form-select>
              </b-form-group>
              <!-- Minimum Transaction Type -->
              <b-form-group
                id="minimum_type"
                label="Min Transaction Type"
                label-for="minimum_type"
              >
                <b-form-select
                  id="minimum_type"
                  v-model="form.minimum_type"
                  :plain="true"
                  :options="[
                    { value: 0, text: 'IDR Amount' },
                    { value: 1, text: 'Gold Amount' }
                  ]"
                >
                </b-form-select>
              </b-form-group>
              <!-- Minimum Transaction Amount -->
              <b-form-group
                id="min_amount"
                label="Min Transaction Amount"
                label-for="min_amount"
              >
                <b-form-input
                  id="min_amount"
                  type="number"
                  v-model="form.min_amount"
                  aria-describedby="maxAllowedFeedback"
                  placeholder="0"
                  autocomplete="given-name"
                  autofocus
                />
              </b-form-group>
              <!-- Promotion -->
              <b-row>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group
                    id="promotion_type"
                    label="Tipe Promosi"
                    label-for="promotion_type"
                  >
                    <b-form-select
                      id="promotion_type"
                      v-model="form.promotion_type"
                      aria-describedby="usertypeFeedback"
                      :plain="true"
                      :options="[
                        { value: '', text: 'Pilih Tipe Promosi' },
                        { value: 0, text: 'Fixed Price' },
                        { value: 1, text: 'Discount' },
                        { value: 2, text: 'Add Gram' }
                      ]"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group
                    id="aamount"
                    label="Nilai Promosi"
                    label-for="amount"
                  >
                    <b-form-input
                      id="aamount"
                      type="number"
                      v-model.lazy.trim="$v.form.amount.$model"
                      :state="chkState('amount')"
                      aria-describedby="amountFeedback"
                      placeholder="0"
                      autocomplete="given-name"
                      autofocus
                    />
                    <b-form-invalid-feedback id="amountFeedback">
                      <span v-if="!$v.form.amount.required"
                        >Promo amount tidak boleh kosong.</span
                      >
                      <span v-if="!$v.form.amount.decimal"
                        >Promo amount harus berupa angka valid.</span
                      >
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group
                    id="quantity"
                    label="Quota (Jumlah User)"
                    label-for="quantity"
                  >
                    <b-form-input
                      id="quantity"
                      type="number"
                      v-model="form.quantity"
                      aria-describedby="maxAllowedFeedback"
                      placeholder="0"
                      autocomplete="given-name"
                      autofocus
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2" v-if="form.promotion_type === 1">
                  <b-form-group
                    id="maxamount"
                    label="Max Amount Promosi"
                    label-for="maxamount"
                  >
                    <b-form-input
                      id="maxamount"
                      type="number"
                      v-model="form.max_amount"
                      aria-describedby="maxattempFeedback"
                      placeholder="0"
                      autocomplete="given-name"
                      autofocus
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2" v-if="form.code_type === 0">
                  <b-form-group
                    id="maxattempt"
                    label="Maximum Usage Per User"
                    label-for="maxattempt"
                  >
                    <b-form-input
                      id="maxattempt"
                      type="number"
                      v-model="form.max_attempt"
                      aria-describedby="maxattempFeedback"
                      placeholder="0"
                      autocomplete="given-name"
                      autofocus
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Show/Hide -->
              <b-form-group
                label="Show in App"
                v-slot="{ ariaDescribedby }"
                v-if="form.code_type === 0"
              >
                <b-form-radio-group
                  v-model="form.status"
                  :options="[
                    { text: 'Show', value: true },
                    { text: 'Hide', value: false }
                  ]"
                  :aria-describedby="ariaDescribedby"
                  name="status"
                ></b-form-radio-group>
              </b-form-group>
              <!-- Index View -->
              <b-form-group
                id="index_view"
                label="Priority Ordering"
                label-for="index_view"
                v-if="this.form.code_type == 0"
              >
                <b-form-input
                  id="index_view"
                  type="text"
                  v-model.lazy.trim="$v.form.index_view.$model"
                  :state="chkState('index_view')"
                  :disabled="isPeriod"
                  aria-describedby="indexViewFeedback"
                  placeholder="Input order of priority"
                  autocomplete="given-name"
                  autofocus
                />
                <b-form-invalid-feedback id="indexViewFeedback">
                  <span v-if="!$v.form.index_view.required"
                    >Priority ordering cannot be empty.</span
                  >
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- S&K -->
              <b-form-group
                id="contentInputGroup2"
                label="Syarat dan Ketentuan"
                label-for="content"
              >
                <quill-editor
                  id="content"
                  style="margin-bottom:5px"
                  v-model="form.content"
                  autocomplete="family-name"
                >
                </quill-editor>
                <span
                  id="contentFeedback"
                  v-if="!$v.form.content.required && submit"
                  >Syarat dan Ketentuan tidak boleh kosong.</span
                >
              </b-form-group>
              <!-- Button -->
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import axios from "axios";
import dotenv from "dotenv";
import moment from "moment";

dotenv.config();
const decimal = helpers.regex(
  "decimal",
  /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/
);

export default {
  name: "generate",
  data() {
    return {
      startDate: "",
      endDate: "",
      partner: [],
      organization: [],
      payment_methods: [],
      modules: [],
      form: {
        name: "",
        short_description: "",
        module: [],
        code_type: 0,
        code: "",
        payment_type: "",
        minimum_type: 0,
        min_amount: "",
        quantity: "",
        content: "",
        day: "",
        amount: "",
        promotion_type: 0,
        user_type: 0,
        register_start: "",
        register_end: "",
        usage_type: 0,
        max_attempt: "",
        organization: "",
        max_amount: "",
        payment_method: "",
        status: false,
        index_view: ""
      },
      errors: {
        message: [],
        status: [],
        code: "",
        headers: [],
        form: {
          name: [],
          short_description: [],
          prefix: [],
          start: [],
          end: [],
          qty: [],
          max_attempt: [],
          max_allowed: [],
          used_for: [],
          v_type: [],
          expired_type: [],
          amount: [],
          index_view: []
        }
      },
      submit: false,
      date: "",
      isLoading: false
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    },
    isPeriod() {
      const endPeriod = this.endDate;
      const isEnd = endPeriod
        ? moment().isAfter(moment(endPeriod, "YYYY-MM-DD HH:mm:ss"))
        : false;

      return !this.form.status || isEnd;
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      form: {
        name: {
          required
        },
        short_description: {
          required,
          maxLength: maxLength(80)
        },
        code: {
          required,
          maxLength: maxLength(15)
        },
        code_type: {
          required
        },
        amount: {
          required,
          decimal
        },
        content: {
          required
        },
        index_view: {
          required: helpers.withParams({ type: "requiredIfNotPeriod" }, value =>
            !this.isPeriod ? required(value) : true
          )
        }
      }
    };
  },
  created() {
    this.onSetCurrentTime();
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
    axios
      .get(
        process.env.VUE_APP_SECRET +
          "admin/v2/command/available-features?only=1,23,32"
      )
      .then(res => {
        this.modules = res.data.data;
      });
  },
  methods: {
    changePicker(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    setChange(val) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
      if (val === 2) {
        axios
          .get(process.env.VUE_APP_SECRET + "admin/v2/command/partner-command")
          .then(response => {
            this.partner = response.data.data;
          });
      }

      if (val === 3) {
        axios
          .get(
            process.env.VUE_APP_SECRET + "admin/v2/command/organization-command"
          )
          .then(response => {
            this.organization = response.data.data;
          });
      }
    },
    setPaymentChange(val) {
      if (val == "") {
        this.payment_methods = [];
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("access_token")}`;
        axios
          .get(
            process.env.VUE_APP_SECRET +
              `admin/v2/command/payment-method?type=${val}`
          )
          .then(res => {
            this.payment_methods = res.data.data;
          });
      }
    },
    onSubmit() {
      this.submit = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.form.module.length === 0) {
          this.$swal.fire("Failed!", "Modul tidak boleh kosong.", "error");
        }
        if (this.startDate === "") {
          this.$swal.fire(
            "Failed!",
            "Promo Period tidak boleh kosong",
            "error"
          );
        }
        this.isLoading = true;
        const payload = {
          code_type: this.form.code_type,
          module: this.form.module,
          name: this.form.name,
          short_description: this.form.short_description,
          start_from: this.startDate,
          end_to: this.endDate,
          usage_type: this.form.usage_type,
          used_for: this.form.usage_type === "All" ? 0 : this.form.organization,
          user_type: this.form.user_type,
          register_start: this.form.register_start,
          register_end: this.form.register_end,
          day: this.form.day,
          promotion_type: this.form.promotion_type,
          code: this.form.code,
          payment_type: this.form.payment_type,
          payment_method: this.form.payment_method,
          minimum_type: this.form.minimum_type,
          min_amount: this.form.min_amount,
          quantity: parseInt(this.form.quantity),
          content: this.form.content,
          amount: this.form.amount,
          max_amount: this.form.max_amount,
          max_attempt: this.form.code_type === 0 ? this.form.max_attempt : 1,
          status: this.form.status,
          index_view: this.form.index_view
        };

        this.$http
          .post(`promotion-code`, payload)
          .then(() => {
            this.isLoading = false;
            this.$router.push("/promotion-codes");
            this.$toasted.success("Promotion successfully created!");
          })
          .catch(err => {
            if (err.response) {
              this.isLoading = false;
              if (
                err.response.data.meta.code === 422 ||
                err.response.data.meta.code === 400
              ) {
                this.$swal.fire(
                  "Failed!",
                  err.response.data.meta.message,
                  "error"
                );
              }
            }
          });
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some(child => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    onSetCurrentTime() {
      const today = new Date().toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      const start = `${today} 00:00:00`;
      const end = `${today} 23:59:59`;

      this.startDate = start;
      this.endDate = end;
    }
  }
};
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}

.mx-datepicker {
  width: 100% !important;
}

#dateFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}

.invalid-feedback {
  font-size: 100% !important;
}

.notValidate {
  border: #f86c6b 1px solid;
}

.validate {
  border: green 1px solid;
}

#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>

<style src="spinkit/scss/spinkit.scss" lang="scss" />
